<template>
  <div
    id="sound"
    ref="post"
    v-bind:style="{
      marginTop: ((marginTop * 1.5) + 6) + 'vh',
      marginRight: randomNumberBetween(-2, 12) + 'rem',
    }"
  >
    <div class="mark orange-bk"></div>
    <audio
      v-if="audioSrc !== null"
      controls
      ref="audioPlayer"
      v-bind:src="audioSrc"
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  </div>
</template>

<script>
import { postMixin } from "./PostMixin.js";
export default {
  name: "PostSound",
  mixins: [postMixin],
  data: function () {
    return {
      audioSrc: null,
    };
  },
  mounted() {
    this.setAssets();
    this.audioSrc = this.assets[0].filepath;
  },
};
</script>

<style scoped>
audio::-webkit-media-controls-panel, video::-webkit-media-controls-panel {
  background-color: #aa3500;
}
.mark {
  position: absolute;
  top: 0;
  width: 0.9rem;
  clip-path: circle();
  height: 0.9rem;
}
</style>
