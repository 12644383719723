<template>
	<div
		id="Modal"
		class="modalVideo"
		ref="modalVideo"
		v-bind:style="{
		position: 'fixed',
		left: (100-videoWidth)*(3/5) + 'vw',
		right: (100-videoWidth)*(2/5) + 'vw',
		width: videoWidth + 'vw',
		height: videoHeight + 'vw',
		top: '50%',
		marginTop: '-' + videoHeight/2 + 'vw'
		}"
	>
		<BlueTextBox
			@clicked="textBoxOpen = !textBoxOpen"
			v-bind:opened="textBoxOpen"
			v-bind:style="{position: 'fixed', left: '2.7vw', 
			height: 23 + 'rem', width: 30 + 'rem',
			zIndex: 44446664}"
		>
			<StrokeTitle 
				v-bind:title="title" 
				v-bind:colorFront="'#22515B'"
				v-bind:colorBack="'blue'"
			/>
			<div v-html="bioText" />	
		</BlueTextBox>
		<ColorFade color="blue"  />
		<div class="content" v-html="content"></div>
		<ColorFade color="blue" />
		<StrokeTitle 
				@title-clicked="$emit('closeModal')"
				v-bind:title="'X'" 
				v-bind:colorFront="'#22515B'"
				v-bind:colorBack="'blue'"
				v-bind:style="{position: 'absolute', left: '82.7vw', 
			height: 3 + 'rem', width: 3 + 'rem',
			
			zIndex: 44446664}"

			/>
	</div>
</template>

<script>
import ColorFade from '../../elements/ColorFade.vue'
			import StrokeTitle from '../../elements/StrokeTitle.vue'
			import BlueTextBox from '../../elements/BlueTextBox.vue'
			import {PixelToViewport} from '../../mixins/PixelToViewport.js'
			export default {
				name: "ResidentinnenSectionVideoModal",
				mixins: [PixelToViewport],
				data: function () {
					return {
						videoWidth: 80,
						textBoxOpen: false
					};
				},
				components: {
					BlueTextBox,
					ColorFade,
					StrokeTitle
				},
				props: {
					content: String,
					bioText: String,
					title: String
				},
				methods: {
					randomNumberBetween(a, b) {
						return Math.random() * (b - a) + a;
					},
				},
				computed: {
					videoHeight() {
						return this.videoWidth / (16 / 9 ) 
					},
				},
				mounted() {
					setTimeout( () => {
					this.textBoxOpen = true
					},200)
				}
			};
</script>

<style scoped>
#Modal {
	background: green;
}
.modalVideo {
	display: flex;
	flex-direction: row;
	z-index: 33333;
	overflow-x: unset;
}
.content {
	width: 100%;
	overflow: hidden;
	height: 100%;
}
</style>

