<template>
	<div
		id="video"
		ref="post"
		v-bind:style="{
		marginTop: marginTop * 0.4 + 'vh',
		marginRight: marginRight + 'rem',
		marginLeft: marginLeft + 'rem',
		width: width + 'rem'
		}"
	>
		<div class="orange-bk mark"></div>
		<PostVideoModal
			v-if="isExpanded"
			v-bind:link="post.contentHTML"
		></PostVideoModal>

		<div
			class="trns cutOut"
			v-bind:style="{
			width: isExpanded ? width + 'rem' : widthClosed + 'rem',
			height: isExpanded ? '100vh' : heightClosed + 'vh',
			}"
			@click="expandShape"
		>
			<img
				class="trns"
				v-bind:src="getShapeById(post.author)"
				v-bind:style="{
				width: width + 'rem',
				}"
			/>
		</div>
	</div>
</template>

<script>
import { postMixin } from "./PostMixin.js";
import { ShapesMixin } from "../../../mixins/ShapesMixin";
                import PostVideoModal from "./PostVideoModal.vue";
                        export default {
                                mixins: [postMixin, ShapesMixin],
                                name: "PostVideo",
                                        components: {
							PostVideoModal,
						},
						data: function () {
							return {
								width: 0,
								widthClosed: 40,
								heightClosed: 57,
								modalVisible: false,
								marginRight: 0,
								marginLeft:0
							};
						},
						methods: {
							expandShape() {
								console.log("expand");
								if (!this.isExpanded) this.isExpanded = !this.isExpanded;
							},
						},
						mounted() {
							this.heightClosed = this.randomNumberBetween(40, 60);
							this.width = 80;
							this.widthClosed = this.randomNumberBetween(25, 35);
							this.marginRight = this.randomNumberBetween(-18,-8)
							this.marginLeft = this.randomNumberBetween(-18,-8)
						},
					};
</script>

<style scoped>
.cutOut {
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
#video {
}
img {
	cursor: pointer;
}
.mark {
	position: absolute;
	top: 0;
	height: 0.9rem;
	width: 0.3rem;
}
</style>
