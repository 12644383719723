export const postMixin = {
  name: 'postMixin',
  methods: {
    randomNumberBetween (a, b) {
      return Math.random() * (b - a) + a
    },
    toggleExpand () {
      this.isExpanded = !this.isExpanded
    },
    deactivateScroll () {
      this.$store.commit('suspendScroll')
    },
    activateScroll () {
      this.$store.commit('activateScroll')
    },
    setAssets () {
      this.assets = this.$store.getters.getAssetsByPostId(this.post.idposts)
    },
    setRandomMarginTop () {
      const min = 0
      const max = 50
      this.marginTop = (Math.random() * (max - min)) + min
    }
  },
  props: {
    post: Object,
    triggerExpand: Number

  },
  data: function () {
    return {
      isExpanded: false,
      assets: [],
      marginTop: 0,
      positionVP: 0
    }
  },
  watch: {
    triggerExpand: function () {
      this.toggleExpand()
    },
    scrollPosition: function () {
      const right = this.$refs.post.getBoundingClientRect().right
      const left = this.$refs.post.getBoundingClientRect().left
      const middle = left + ((right - left) / 2)
      this.positionVP = middle
    },
    positionVP: function (oldVal, newVal) {
      const ltr = (
        oldVal <= this.posTriggerOpenLTR &&
				newVal >= this.posTriggerOpenLTR
      )
      const rtl = (
        oldVal >= this.posTriggerOpenRTL &&
				newVal <= this.posTriggerOpenRTL
      )
      if (ltr || rtl) {
        this.isExpanded = true
      } else if (
        newVal < this.posTriggerCloseLTR ||
				newVal > this.posTriggerCloseRTL
      ) {
        this.isExpanded = false
      }
    }
  },
  computed: {
    scrollPosition () {
      return this.$store.getters.getScrollLeftPx
    },
    screenWidth () {
      return this.$store.getters.getScreenWidthPx
    },
    posTriggerOpenLTR () {
      return this.screenWidth * (4.4 / 5)
    },
    posTriggerOpenRTL () {
      return this.screenWidth * (1.5 / 5)
    },
    posTriggerCloseRTL () {
      return this.screenWidth * (4.4 / 5)
    },
    posTriggerCloseLTR () {
      return this.screenWidth * (1.5 / 5)
    }

  },
  mounted () {
    this.setRandomMarginTop()
  }
}
