<template>
	<div class="BlueTextBox">
		<ColorFade 
			@click.native="$emit('clicked')"
			color="blue"
			v-bind:style="{
			zIndex: opened ? '100' : 'unset'
			}"
		/>
		<div 

			@click="$emit('clicked')"
			class="TextBoxContent red-cl transition"
			@mouseenter="mouseenter"
			@mouseleave="mouseleave"
			v-bind:style="{
			width: opened ? '100%' : '0%',
			background: opened ? '#b2b2b2' : 'rgb(0,0,0,0)' ,
			overflowY: opened ? 'auto' : 'hidden'}"
		>
			<slot></slot>
		</div>
		<ColorFade 
			color="blue"
			class="transition" 
			v-bind:style="{
			zIndex: opened ? '100' : 'unset',
			opacity: opened ? '100' : '0' }"/>
	</div>
</template>

<script>
import ColorFade from './ColorFade.vue'
				export default {
					name: "BlueTextBox",
					components: {
						ColorFade
					},
					props:{ 
						opened: Boolean
					},
					data () {
						return {
						}
					},
					mounted () {
					},
					created () {
					},
					computed: {
					},
					methods: {
						mouseenter(){
							if ( this.opened ) {
								this.$store.commit('suspendScroll')
							}
						},
						mouseleave() {
							this.$store.commit('activateScroll')
						}
					},
					watch: {
						opened: function(oldVal, newVal) {
							if (oldVal && !newVal) {
								this.$store.commit('activateScroll')
							}
						}
					}
				}
</script>

<style scoped >
.BlueTextBox {
	display: flex;
	flex-direction: row;
}
.TextBoxContent {
	padding-left: 1.2rem;
	padding-right: 1rem;
	overflow-x: hidden;	
}
</style>

