<template>
	<BlueTextBox 
		@clicked="boxOpen = !boxOpen"
		id="IntroText" 
		v-bind:opened="boxOpen"
	>
		<ColorFade
			color="blue"
			id="MarkierungIntroText"
		/>
		<StrokeTitle 
			v-bind:title="'HOLD  HANDS'" 
			v-bind:colorFront="'#22515B'"
			v-bind:colorBack="'blue'"
		/>
		<p class="ersterAbsatz">hat erstmalig im April 2016 in den Räumen des Kulturvereins Pracht e.V. Leipzig stattgefunden und wurde durch die Künstlerin Lissy Willberg initiiert. Es war eine Veranstaltung, die queere und weibliche* Positionen in Performance-, Poetry-, Video- und Installationskunst zusammenbrachte und ausstellte. Vier Studierende der Hochschule für Grafik und Buchkunst sowie internationale Künstler:innen haben sich mit Beiträgen am Programm beteiligt.  </p>
		<p class="rechts">Die Idee von Hold Hands als Plattform für den Austausch und die Präsentation dieser Positionen sollte im Frühjahr 2020 fortgesetzt werden; eine öffentliche Veranstaltung konnte jedoch aufgrund der globalen Corona-Pandemie nicht stattfinden. Infolgedessen entstand das Projekt Hold Hands Residenz.</p>
		<p>Viele Künstler:innen sehen sich aktuell vor der Herausforderung digitale Räume zu bespielen. Die Initiator:innen von Hold Hands Residenz sehen ein Defizit in der theoretischen und praktischen Beschäftigung mit dieser Thematik und möchten durch das Residenzprogramm einen digitalen Experimentierraum schaffen, der einen kollektiven Diskurs anregt und die Hinterfragung konventioneller Methodik fördert. </p>
		<p class="rechts">Auf unserer Webseite könnt ihr als Besucher:innen mit den Resident:innen Monika Gabriela Dorniak, Sarafina McLeod und Sunny Pfalzer in Kontakt treten und ihre Arbeitsprozesse verfolgen. Nutzt dazu den Fragenkatalog oder verfolgt die Inhalte, die von den Residierenden online gestellt wurden. Jeder Inhalt wird von den Künstler:innen mit einer Frage verknüpft.</p>
		<p>Außerdem habt ihr die Möglichkeit an einem der öffentlichen Studiobesuche teilzunehmen und so einen Einblick in die Residenz zu bekommen und mit den Resident:innen in Austausch zu treten.</p>
	</BlueTextBox>
</template>

<script>
import ColorFade from '../../elements/ColorFade.vue'
import {BoxOpenMixin} from '../../mixins/BoxOpenMixin.js'
import StrokeTitle from '../../elements/StrokeTitle.vue'
import BlueTextBox from '../../elements/BlueTextBox.vue'
export default {
	name: "InfoSectionIntroText",
	mixins: [BoxOpenMixin],
	components: {
		StrokeTitle,
		BlueTextBox,
		ColorFade
	},
	props: [
	],
	data () {
		return {
			boxOpen: false,
			openAt: 150,
			closeAt: 300,
			openByScroll: true
		}
	},
	mounted () {
	},
	created () {
	},
	computed: {
	},
	methods: {
	},
	watch: {
	}
}
</script>

<style scoped >
#IntroText {
	position: absolute;
	top: 0.95rem;
	left: 64rem;
	height: 16rem;
	width: 35rem;
}
.ersterAbsatz {
	text-indent: 10rem;
	margin-top: 0.65rem;
}
#MarkierungIntroText {
	height: 2rem;
	position: absolute;
	top: -2rem;
	left: 0;
}
.ersterAbsatz {
	padding-top: 0.1rem;
	text-indent: 12.8rem;
}
</style>
