<template>
	<div class="BlueFade" v-bind:class="{mirrored:mirrored}">
		<img class="FadeWidth" v-bind:src="pathToFade"> 
	</div>
</template>

<script>
export default {
  props: {
    mirrored: Boolean,
	color: String
  },
  name: "ColorFade",
	data () {
		return {
			orangeFade: require("../../../assets/fades/Verlauf_orange.png"),
			blueFade: require("../../../assets/fades/Verlauf_blau.png"),
		}
	},
	computed: {
		pathToFade() {
			switch (this.color) {
				case 'orange':
					return this.orangeFade
				case 'blue':
					return this.blueFade
			}
			return this.orangeFade
		}
	}
}
</script>

<style scoped>
img {
  position: absolute;
  height: 100%;
  left:-0.69rem;
  z-index: 100;
}
.BlueFade {
  position: relative;
  width: 0;
  z-index: 100;
}
.mirrored { transform: scaleX(-1);}
</style>
