var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"post",staticClass:"trns",style:({
                               marginTop: (_vm.marginTop + 2) + 'vh',
                               height: _vm.height + 'vh',
                               width: _vm.widthOpen + 'rem',
                               marginRight: _vm.marginRight + 'rem'
                               }),attrs:{"id":"longText"},on:{"click":_vm.toggleExpand}},[_c('ColorFade',{staticClass:"mark",attrs:{"color":"orange"}}),_c('ColorFade',{attrs:{"color":"orange"}}),_c('div',{staticClass:"textBox trns",style:({
                                               opacity: _vm.isExpanded ? '100' : '0',
                                               width: _vm.isExpanded ? '100%' : '0%',
                                               }),on:{"mouseenter":_vm.deactivateScroll,"mouseleave":_vm.activateScroll}},[_vm._v(" "+_vm._s(_vm.post.contentHTML)+" ")]),_c('ColorFade',{style:({
                                                  transition: '2.4s',
                                                  opacity: _vm.isExpanded ? 100 : 0,
                                                  }),attrs:{"color":"orange"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }