var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modalVideo",staticClass:"modalVideo",style:({
	position: 'fixed',
	left: (100-_vm.videoWidth)*(3/5) + 'vw',
	right: (100-_vm.videoWidth)*(2/5) + 'vw',
	width: _vm.videoWidth + 'vw',
	height: _vm.videoHeight + 'vw',
	top: '50%',
	marginTop: '-' + _vm.videoHeight/2 + 'vw'
	}),attrs:{"id":"Modal"}},[_c('BlueTextBox',{style:({position: 'fixed', left: '2.7vw', 
		height: 23 + 'rem', width: 30 + 'rem',
		zIndex: 44446664}),attrs:{"opened":_vm.textBoxOpen},on:{"clicked":function($event){_vm.textBoxOpen = !_vm.textBoxOpen}}},[_c('StrokeTitle',{attrs:{"title":_vm.title,"colorFront":'#22515B',"colorBack":'blue'}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.bioText)}})],1),_c('ColorFade',{attrs:{"color":"blue"}}),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.content)}}),_c('ColorFade',{attrs:{"color":"blue"}}),_c('StrokeTitle',{style:({position: 'absolute', left: '82.7vw', 
		height: 3 + 'rem', width: 3 + 'rem',
		
		zIndex: 44446664}),attrs:{"title":'X',"colorFront":'#22515B',"colorBack":'blue'},on:{"title-clicked":function($event){return _vm.$emit('closeModal')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }