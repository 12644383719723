<template>
	<div
		id="DatenschutzSection"
		ref="Section"
		class="red-bk"
	>
		<div class="NavigationLine blue-bk"></div>
		<BlueTextBox 
		@clicked="boxOpen = !boxOpen"
			id="Datenschutzerkl�rung" 
			v-bind:opened="boxOpen"
		>
			<StrokeTitle 
				v-bind:title="'DATENSCHUTZ'" 
				v-bind:colorFront="'#22515B'"
				v-bind:colorBack="'blue'"
			/>
			<br>
			<p>Verantwortliche im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>

			<p>mail ( at ) hold-hands.net
				<br>Hold Hands Residenz
				<br>c/o Lissy Willberg
				<br>Herbartstr. 75
				<br>04318 Leipzig</p>
			<h2>Ihre Betroffenenrechte</h2>
			<p>Unter den angegebenen Kontaktdaten unserer Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
			<ul>
				<li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
				<li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
				<li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
				<li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
				<li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
				<li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
			</ul>
			<p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
			<p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
			<p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
			<p></p><h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
			<h3>Art und Zweck der Verarbeitung:</h3>
			<p>Wenn Sie auf unsere Website zugreifen, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. </p>
			<p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
			<ul>
				<li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
				<li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
				<li>Auswertung der Systemsicherheit und -stabilität sowie</li>
				<li>zur Optimierung unserer Website.</li>
			</ul>
			<p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren. </p>
			<h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
			<p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.</p>
			<h3>Empfänger:innen:</h3>
			<p>Empfänger:innen der Daten sind ggf. technische Dienstleister:innen, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter:innen tätig werden.</p>
			<p></p><h3>Speicherdauer:</h3>
			<p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist. </p>
			<p> Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer:innen anonymisiert, sodass eine  Zuordnung  des aufrufenden Clients nicht mehr möglich ist.</p>
			<p></p><h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
			<p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen. </p>
			<p></p><h2>Kommentarfunktion</h2>
			<h3>Art und Zweck der Verarbeitung:</h3>
			<p>Wenn Nutzer:innen Kommentare auf unserer Website hinterlassen, werden neben diesen Angaben auch der Zeitpunkt ihrer Erstellung gespeichert. Dies dient unserer Sicherheit, da wir für widerrechtliche Inhalte auf unserer Webseite belangt werden können, auch wenn diese durch Benutzer:innen erstellt wurden.</p>
			<h3>Rechtsgrundlage:</h3>
			<p>Die Verarbeitung der als Kommentar eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
			<p>Durch Bereitstellung der Kommentarfunktion möchten wir Ihnen eine unkomplizierte Interaktion ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
			<h3>Empfänger:</h3>
			<p>Empfänger:innen der Daten sind ggf. Auftragsverarbeiter:innen.</p>
			<p></p><h3>Drittlandtransfer:</h3>
			<p>Folgende Datenschutzgarantien liegen vor: </p>
			<p></p><h3>Speicherdauer:</h3>
			<p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist grundsätzlich der Fall, wenn die Kommunikation mit dem Nutzer abgeschlossen ist und das Unternehmen den Umständen entnehmen kann, dass der betroffene Sachverhalt abschließend geklärt ist. Wir behalten uns die Löschung ohne Angaben von Gründen und ohne vorherige oder nachträgliche Information vor.</p>
			<p>Außerdem können Sie Ihren Kommentar jederzeit durch uns löschen lassen. Schreiben Sie dafür bitte eine E-Mail an den unten aufgeführten Datenschutzbeauftragten bzw. die für den Datenschutz zuständige Person und übermitteln den Link zu Ihrem Kommentar sowie zu Identifikationszwecken die bei der Erstellung des Kommentars verwendete E-Mail-Adresse.</p>
			<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
			<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang zu unserer Kommentarfunktion gewähren.</p>
			<p></p><h2>Kontaktformular</h2>
			<h3>Art und Zweck der Verarbeitung:</h3>
			<p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
			<h3>Rechtsgrundlage:</h3>
			<p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
			<p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
			<p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
			<h3>Empfänger:innen:</h3>
			<p>Empfänger:innen der Daten sind ggf. Auftragsverarbeiter.</p>
			<p></p><h3>Speicherdauer:</h3>
			<p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
			<p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen. </p>
			<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
			<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
			<p></p><h2>Eingebettete YouTube-Videos</h2>
			<p>Auf unserer Website betten wir YouTube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA (nachfolgend „YouTube“). Die YouTube, LLC ist einer Tochtergesellschaft der Google LLC, 1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA (nachfolgend „Google“). Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen. </p>
			<p>Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln. </p>
			<p>Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in den Datenschutzerklärungen des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre (<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>). </p>
			<h3>Widerruf der Einwilligung:</h3>
			<p>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.</p>
			<p></p><h2>SSL-Verschlüsselung</h2>
			<p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
			<p></p><hr>
			<h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
			<h3>Einzelfallbezogenes Widerspruchsrecht</h3>
			<p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
			<p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
			<h3>Empfänger eines Widerspruchs</h3>
			<p>mail ( at ) hold-hands.net
				<br>Hold Hands Residenz
				<br> c/o Lissy Willberg
				<br>Herbartstr. 75
				<br>04318 Leipzig</p>
			<hr>
			<h2>�nderung unserer Datenschutzbestimmungen</h2>
			<p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
			<h2>Fragen an den Datenschutzbeauftragten</h2>
			<p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
			<p></p><p><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noopener">externe Datenschutzbeauftragte</a> (Version #2020-09-30).</em></p>


		</BlueTextBox>
	</div>
</template>

<script>
import StrokeTitle from '../../elements/StrokeTitle.vue'
import BlueTextBox from '../../elements/BlueTextBox.vue'
import {SectionMixin} from '../../mixins/SectionMixin.js'
export default {
	name: "DatenschutzSection",
	mixins: [SectionMixin],
	components: {
		StrokeTitle,
		BlueTextBox
	},
	props: [
	],
	data () {
		return {
			boxOpen: true
		}
	},
	mounted () {
	},
	created () {
	},
	computed: {
	},
	methods: {
	},
	watch: {
	}
}
</script>

<style scoped >
#DatenschutzSection {
	width: 45rem;
	position: relative;
	height: 100%;
}
#Datenschutzerkl�rung {
	position: absolute;
	bottom: 7rem;
	left: 4rem;
	height: 64vh;
	width: 37rem;
}
</style>
