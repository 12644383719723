export const PixelToViewport = {
  name: 'PixelToViewport',
  data: function () {
    return {
      // font size in global.css is 1.8vh
      baseFontSizeAsInGlobalCSS: 1.8
    }
  },
  methods: {
    pxToVh (px) {
      return (px / this.$store.getters.getScreenHeight) *
 100
    },
    pxToVw (px) {
      return (px / this.$store.getters.getScreenWidthPx) * 100
    },
    VhToPx (vh) {
      return (vh / 100) * this.$store.getters.getScreenHeight
    },
    VwToPx (vw) {
      return (vw / 100) * window.innerWidth
    },
    VwToVh (vw) {
      const widthPx = vw * this.$store.getters.getScreenWidthPx / 100
      return this.pxToVh(widthPx)
    },
    RemToPx (rem) {
      return this.VhToPx(this.baseFontSizeAsInGlobalCSS) * rem
    },
    PxToRem (px) {
      return this.pxToVh(px) / this.baseFontSizeAsInGlobalCSS
    }
  }
}
