<template>
<div id="PostContainer" v-if="$store.getters.getAssets.length > 0">
  <PostTextLong v-if="post.category === 'textLong'" v-bind:post="post"></PostTextLong>
  <PostImage v-if="post.category === 'picture'" v-bind:post="post"></PostImage>
  <PostVideo v-if="post.category === 'video'" v-bind:post="post"></PostVideo>
  <PostSound v-if="post.category === 'sound'" v-bind:post="post"></PostSound>
</div>
</template>

<script>
import PostImage from "./PostImage.vue";
import PostTextLong from "./PostTextLong.vue";
import PostVideo from "./PostVideo.vue";
import PostSound from "./PostSound";
export default {
  name: "PostContainer",
  components: {PostSound, PostVideo, PostTextLong, PostImage},
  props: {
    post: Object
  },
  data: function () {
    return {
      marginTop: 0,
      marginRight: 0
    }
  },
  mounted() {
    this.setRandomMarginTop()
    this.setRandomMarginRight()
  },
  methods: {
    setRandomMarginTop() {
      let min = 0
      let max = 50
      this.marginTop = (Math.random() * ( max - min )) + min
    },
    setRandomMarginRight() {
      let min = 0
      let max = 0
      this.marginRight = (Math.random() * ( max - min )) + min
    }
  }
}
</script>

<style scoped>
#PostContainer{
  position: relative;
}

</style>
