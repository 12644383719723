var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
				marginLeft: _vm.currentSection === 'ResidenzSection' ? _vm.residenzMargin + 'px' : 'unset',
		}),attrs:{"id":"NavigationMenu"}},[_c('div',{staticClass:"buttons",class:{buttonHeight: _vm.minimized, fullHeight: !_vm.minimized},style:({
			opacity: _vm.opacityBlueButtons
			}),on:{"mouseenter":function($event){_vm.minimized = false},"mouseleave":function($event){_vm.minimized = true}}},[_c('img',{staticClass:"buttonHeight",style:({order: _vm.orderDatenschutz, background: _vm.orderDatenschutz === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}),attrs:{"src":_vm.pathDatenschutzBlau},on:{"click":_vm.goToDatenschutz}}),_c('img',{staticClass:"buttonHeight",style:({order: _vm.orderInfo, background: _vm.orderInfo === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}),attrs:{"src":_vm.pathInfoBlau},on:{"click":_vm.goToInfo}}),_c('img',{staticClass:"buttonHeight",style:({order: _vm.orderResidentinnen, background: _vm.orderResidentinnen === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}),attrs:{"src":_vm.pathResidentinnenBlau},on:{"click":_vm.goToResidentinnen}}),_c('img',{staticClass:"buttonHeight",style:({order: _vm.orderResidenz, background: _vm.orderResidenz === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}),attrs:{"src":_vm.pathResidenzBlau},on:{"click":_vm.goToResidenz}}),_c('img',{staticClass:"buttonHeight",style:({order: _vm.orderAusstellung, background: _vm.orderAusstellung === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}),attrs:{"src":_vm.pathAusstellungBlau},on:{"click":_vm.goToAusstellung}})]),_c('NavigationFilterResidenz',{directives:[{name:"show",rawName:"v-show",value:(
			_vm.currentSection === 'ResidenzSection'
			&&
			_vm.$store.getters.getScrollLeftPx + this.$store.getters.getScreenWidthPx < _vm.$store.getters.getStartAusstellung ),expression:"\n\t\t\tcurrentSection === 'ResidenzSection'\n\t\t\t&&\n\t\t\t$store.getters.getScrollLeftPx + this.$store.getters.getScreenWidthPx < $store.getters.getStartAusstellung "}]}),_c('div',{staticClass:"buttons",class:{buttonHeight: _vm.minimized, fullHeight: !_vm.minimized},style:({
			opacity:
                                _vm.$store.getters.currentSection === 'ResidenzSection' 
                                && 
                                _vm.$store.getters.getScrollLeftPx < _vm.$store.getters.getStartAusstellung - 230
                                ? '100' : '0' ,
			}),on:{"mouseenter":function($event){_vm.minimized = false},"mouseleave":function($event){_vm.minimized = true}}},[_c('img',{staticClass:"buttonHeight",style:({
				order: _vm.orderDatenschutz,
				zIndex: _vm.currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: _vm.orderDatenschutz === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}),attrs:{"src":_vm.pathDatenschutzOrange},on:{"click":_vm.goToDatenschutz}}),_c('img',{staticClass:"buttonHeight",style:({
				order: _vm.orderInfo,
				zIndex: _vm.currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: _vm.orderInfo === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}),attrs:{"src":_vm.pathInfoOrange},on:{"click":_vm.goToInfo}}),_c('img',{staticClass:"buttonHeight",style:({
				order: _vm.orderResidentinnen,
				zIndex: _vm.currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: _vm.orderResidentinnen === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}),attrs:{"src":_vm.pathResidentinnenOrange},on:{"click":_vm.goToResidentinnen}}),_c('img',{staticClass:"buttonHeight",style:({
				order: _vm.orderResidenz,
				zIndex: _vm.currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: _vm.orderResidenz === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}),attrs:{"src":_vm.pathResidenzOrange},on:{"click":_vm.goToResidenz}}),_c('img',{staticClass:"buttonHeight",style:({
				order: _vm.orderAusstellung, 
				zIndex: _vm.currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: _vm.orderAusstellung === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}),attrs:{"src":_vm.pathAusstellungOrange},on:{"click":_vm.goToAusstellung}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }