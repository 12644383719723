<template>
	<div
		class="red-bk"
		id="InfoSection"
		ref="Section"
	>
		<div class="NavigationLine blue-bk"></div>
		<img id="HoldHandsLogo" src="../../../../assets/logo/Logo.png" />
		<InfoSectionImpressum/>
		<InfoSectionIntroText/>
		<InfoSectionCalendar/>
	</div>
</template>

<script>
import {SectionMixin} from '../../mixins/SectionMixin.js'
import InfoSectionImpressum from './InfoSectionImpressum.vue'
import InfoSectionIntroText from './InfoSectionIntroText.vue'
import InfoSectionCalendar from './InfoSectionCalendar.vue'
export default {
	name: "InfoSection",
	mixins: [SectionMixin],
	components: {
		InfoSectionImpressum,
		InfoSectionIntroText,
		InfoSectionCalendar
	},
	props: [
	],
	data () {
		return {
		}
	},
	mounted () {
	},
	created () {
	},
	computed: {
	},
	methods: {
	},
	watch: {
	}
}
</script>

<style scoped >
#InfoSection {
	height: 100%;
	width: 120rem;
	position: relative;
}
#HoldHandsLogo {
	z-index: 0;
	position: absolute;
	height: 21rem;
	margin-top: 8rem;
	margin-left:14rem;
}
</style>
