export const BoxOpenMixin = {
  name: 'BoxOpenMixin',
  data: function () {
    return {
      openByScroll: false,
      openAt: 300,
      closeAt: 400,
      boxOpen: false
    }
  },
  watch: {
    scrollPosRem: function (newVal, oldVal) {
      if (this.openByScroll) {
        if (oldVal < this.openAt && newVal > this.openAt) {
          this.boxOpen = true
        } else if (oldVal < this.closeAt && newVal > this.closeAt) {
          this.boxOpen = false
        }
      }
    }
  },
  computed: {
    scrollPosRem () {
      return this.$store.getters.getScrollLeftPx / this.$store.getters.getScreenHeight * 180
    }
  }
}
