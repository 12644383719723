<template>
	<div id="Impressum">
		<ColorFade
		color="blue"
		id="MarkierungImpressum"
	/>
<BlueTextBox 
		@clicked="boxOpen = !boxOpen"
	id="ImpressumTextBox" 
	v-bind:opened="boxOpen"
>
<StrokeTitle 
		v-bind:title="'IMPRESSUM'" 
		v-bind:colorFront="'#22515B'"
		v-bind:colorBack="'blue'"
	/>
	<p class="ersterAbsatz rechts">Das Projekt Hold Hands Residenz wird
		umgesetzt von:</p>
	<p>
		Hjördis Lyn Behncken (Öffentlichkeitsarbeit)<br>
		Anne Dietzsch (Gestaltung und Typografie)<br>
		Jonathan Schneider (Programmierung)<br>
		Lissy Willberg (Künstlerische Leitung)<br>
	</p>
	<p class="rechts">
		Gefördert wird das Projekt durch das Kulturamt 
		Leipzig und das Support-Büro der HGB Leipzig.
	</p>
	<address class="">
		Kontakt:
		<br>
		mail ( at ) hold-hands.net
		<br>
		Hold Hands Residenz <br>
		c/o Lissy Willberg <br>
		Herbartstr. 1p <br>
		04318 Leipzig
		<br>
			</address>
		</BlueTextBox>
	</div>
	</template>

<script>
import ColorFade from '../../elements/ColorFade.vue'
import StrokeTitle from '../../elements/StrokeTitle.vue'
import BlueTextBox from '../../elements/BlueTextBox.vue'
export default {
name: "InfoSectionImpressum",
components: {
	StrokeTitle,
	BlueTextBox,
	ColorFade
},
props: [
],
data () {
	return {
		boxOpen: false,
		impressumText: ""
	}
},
mounted () {
},
created () {
},
computed: {
},
methods: {
},
watch: {
}
}
</script>

<style scoped >
#ImpressumTextBox {
position: absolute;
bottom: 0;
height: 16rem;
width: 30rem;
}
#Impressum {
	position: relative;
left: 11rem;
height: 100%;
}
.ersterAbsatz {
text-indent: 11.5rem;
margin-top: 0.65rem;
}
address {
	font-style: normal;
}
#MarkierungImpressum {
	position: absolute;
	height: 1.0rem;
	position: absolute;
	left: 0;
	top:0;
}
</style>
