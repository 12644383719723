<template>
	<div
		class="title-wrapper"
		v-bind:style="{zIndex: zIndex}"
	>
		<svg
			@click="$emit('title-clicked')" 
			v-bind:style="{zIndex: zIndex, padding: 0, overflow: 'visible'}"
     >
	<text 
	x = "5" 
	y="00" 
	class="back fontTitle"
	v-bind:style="{stroke: colorBack}"
	>{{title}}</text>

	<text 
	x = "5" 
	y="00" 
	class="front fontTitle"
	v-bind:style="{fill: colorFront}"
	>{{title}}</text>
  </svg>
</div>
</template>

<script>
export default {
name: "StrokeTitle",
  props: {
    title: String,
	colorFront: String,
	colorBack: String,
    zIndex: Number
  }
}
</script>

<style scoped>
svg{
  position: relative;
  cursor: pointer;
  top: 2.6rem;

}
.title-wrapper {
  z-index: 33333;
  position: absolute;
  width: 4rem;
  height: 1rem;
  left: 0.4rem;
  top: 0.1rem;
  overflow: visible;
  transition: z-index 0s;
  transform: scaleX(0.85);
  transform-origin: top left;
}

.back {
  cursor: pointer;
  transition: z-index 0s;
  display: block;
  width: 100%;
  stroke-width:1rem;
  stroke-linejoin: round;
}
.front {
  transition: z-index 0s;
  width: 100%;
  display: block;
}

</style>
