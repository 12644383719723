var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"post",staticClass:"trns",style:({
      width: 40  + 'rem',
	marginRight: _vm.marginRight + 'rem'
    }),attrs:{"id":"image"},on:{"click":_vm.toggleExpand}},[_c('div',{staticClass:"orange-bk mark"}),(_vm.assets.length > 0)?_c('div',{staticClass:"wrapper trns",style:({
        marginTop: _vm.isExpanded ? (_vm.marginTop/3)+3 +'vh' : (_vm.marginTop + 3 ) * 1.6 + 'vh',
        height: _vm.isExpanded ? (78.3+ (Math.pow( _vm.numberOfPictures, 1.5 ))) + 'vh'
          : _vm.smallImageHeight * _vm.numberOfRows + 'vh',
      })},_vm._l((_vm.assets),function(asset){return _c('img',{key:asset.idpost_assets,staticClass:"trns",style:({
          height: 100 / _vm.numberOfRows + '%',
          top: _vm.isExpanded ? Math.random() * 2+ 'rem' :
            Math.random() * _vm.randomnessPosition - _vm.randomnessPosition / 2 + 'rem',
          left: _vm.isExpanded ? Math.random() * 2 + 'vw' :
            Math.random() * _vm.randomnessPosition - _vm.randomnessPosition / 2 + 'rem',
        }),attrs:{"src":asset.filepath}})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }