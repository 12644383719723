<template>
	<div
		id="NavigationMenu"
		v-bind:style="{
				marginLeft: currentSection === 'ResidenzSection' ? residenzMargin + 'px' : 'unset',
		}"
	>
		<div 
			class="buttons" 
			@mouseenter="minimized = false"
			@mouseleave="minimized = true"
			v-bind:style="{
			opacity: opacityBlueButtons
			}"
			v-bind:class="{buttonHeight: minimized, fullHeight: !minimized}"
		>
			<img 
				@click="goToDatenschutz"
				class="buttonHeight" 
				v-bind:src="pathDatenschutzBlau" 
				v-bind:style="{order: orderDatenschutz, background: orderDatenschutz === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}"/>
			<img
				@click="goToInfo"
				class="buttonHeight" 
				v-bind:src="pathInfoBlau" 
				v-bind:style="{order: orderInfo, background: orderInfo === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}"/>
			<img
				@click="goToResidentinnen"
				class="buttonHeight" 
				v-bind:src="pathResidentinnenBlau" 
				v-bind:style="{order: orderResidentinnen, background: orderResidentinnen === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}"/>
			<img
				@click="goToResidenz"
				class="buttonHeight" 
				v-bind:src="pathResidenzBlau" 
				v-bind:style="{order: orderResidenz, background: orderResidenz === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}"/>
			<img
				@click="goToAusstellung"
				class="buttonHeight" 
				v-bind:src="pathAusstellungBlau" 
				v-bind:style="{order: orderAusstellung, background: orderAusstellung === 1 ? '#3c2832' : 'rgb(0,0,0,0)'}"/>
		</div>
		<NavigationFilterResidenz v-show="
			currentSection === 'ResidenzSection'
			&&
			$store.getters.getScrollLeftPx + this.$store.getters.getScreenWidthPx < $store.getters.getStartAusstellung "
                     />
		<div 
			class="buttons" 
			@mouseenter="minimized = false"
			@mouseleave="minimized = true"
			v-bind:style="{
			opacity:
                                $store.getters.currentSection === 'ResidenzSection' 
                                && 
                                $store.getters.getScrollLeftPx < $store.getters.getStartAusstellung - 230
                                ? '100' : '0' ,
			}"
			v-bind:class="{buttonHeight: minimized, fullHeight: !minimized}"
		>
			<img 
				@click="goToDatenschutz" 
				class="buttonHeight" 
				v-bind:src="pathDatenschutzOrange"  
				v-bind:style="{
				order: orderDatenschutz,
				zIndex: currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: orderDatenschutz === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}"/>
			<img
				@click="goToInfo" 
				class="buttonHeight" 
				v-bind:src="pathInfoOrange"  
				v-bind:style="{
				order: orderInfo,
				zIndex: currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: orderInfo === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}"/>
			<img
				@click="goToResidentinnen"
				class="buttonHeight"
				v-bind:src="pathResidentinnenOrange"
				v-bind:style="{
				order: orderResidentinnen,
				zIndex: currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: orderResidentinnen === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}"/>
			<img 
				@click="goToResidenz"
				class="buttonHeight"
				v-bind:src="pathResidenzOrange"
				v-bind:style="{
				order: orderResidenz,
				zIndex: currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: orderResidenz === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}"/>
			<img 
				@click="goToAusstellung"
				class="buttonHeight"
				v-bind:src="pathAusstellungOrange"
				v-bind:style="{
				order: orderAusstellung, 
				zIndex: currentSection === 'ResidenzSection' ? '1200' : 'unset',
				background: orderAusstellung === 1 ? '#b2b2b2' : 'rgb(0,0,0,0)'
				}"/>
		</div>
	</div>
</template>

<script>
import NavigationFilterResidenz from './NavigationFilterResidenz.vue'
import {PixelToViewport} from '../../mixins/PixelToViewport.js'
export default {
	name: "NavigationMenu",
	mixins:[PixelToViewport],
	components: {
		NavigationFilterResidenz
	},
	props: [
	],
	data () {
		return {
			minimized: false,

			pathInfoBlau: require('../../../../assets/navigation/Menu-buttons-info-blau.png'),
			pathResidentinnenBlau: require('../../../../assets/navigation/Menu-buttons-residentinnen-blau.png'),
			pathResidenzBlau: require('../../../../assets/navigation/Menu-buttons-residenz-blau.png'),
			pathAusstellungBlau: require('../../../../assets/navigation/Menu-buttons-ausstellung-blau.png'),
			pathDatenschutzBlau: require('../../../../assets/navigation/Menu-buttons-datenschutz-blau.png'),

			pathDatenschutzOrange: require('../../../../assets/navigation/Menu-buttons-datenschutz-orange.png'),
			pathInfoOrange: require('../../../../assets/navigation/Menu-buttons-info-orange.png'),
			pathResidentinnenOrange: require('../../../../assets/navigation/Menu-buttons-residentinnen-orange.png'),
			pathResidenzOrange: require('../../../../assets/navigation/Menu-buttons-residenz-orange.png'),
			pathAusstellungOrange: require('../../../../assets/navigation/Menu-buttons-ausstellung-orange.png'),

			orderDatenschutz: 1,
			orderInfo: 2,
			orderResidentinnen: 3,
			orderResidenz: 4,
			orderAusstellung: 5,
		}
	},
	mounted () {
	},
	created () {
	},
	computed: {
		opacityBlueButtons() {
			let opacity = 100
			let marginToResidenzSection = this.RemToPx(19)	
			if ( 
				this.scrollLeft > this.startResidenz - marginToResidenzSection
				&&
				this.scrollLeft < this.startAusstellung 
			) {
				opacity = 0
			}
			return opacity 
		},
		scrollLeft() {
			return this.$store.getters.getScrollLeftPx
		},
		startResidenz() {
			return this.$store.getters.getStartResidenz
		},
		startAusstellung() {
			return this.$store.getters.getStartAusstellung
		},
		currentSection() {
			return this.$store.getters.currentSection
		},
		residenzMargin() {
			let baseMarginRem = 41.6 
			let basePosScrollLeft = this.startResidenz + this.RemToPx(baseMarginRem)
			return Math.max(0, basePosScrollLeft - this.scrollLeft)

		},

	},
	methods: {
		goToDatenschutz(){
			document.getElementById('HorizontalScroll').scrollTo({left: '1', behavior: "smooth"})
		},
		goToInfo(){
			let pos = this.$store.getters.getStartInfo
			document.getElementById('HorizontalScroll').scrollTo({left: pos, behavior: "smooth"})
		},
		goToResidentinnen(){
			let pos = this.$store.getters.getStartResidentinnen
			document.getElementById('HorizontalScroll').scrollTo({left: pos, behavior: "smooth"})
		},
		goToResidenz(){
			let pos = this.$store.getters.getStartResidenz
			document.getElementById('HorizontalScroll').scrollTo({left: pos, behavior: "smooth"})
		},
		goToAusstellung(){
			let pos = this.$store.getters.getStartAusstellung
			document.getElementById('HorizontalScroll').scrollTo({left: pos, behavior: "smooth"})
		}
	},
	watch: {
		currentSection: function(section) {
			switch( section ){
				case "DatenschutzSection":
					this.orderDatenschutz = 1
					this.orderInfo = 2
					this.orderResidentinnen = 3
					this.orderResidenz = 4
					this.orderAusstellung = 5
					break;
				case "InfoSection":
					this.orderDatenschutz = 5
					this.orderInfo = 1
					this.orderResidentinnen = 2
					this.orderResidenz = 3
					this.orderAusstellung = 4
					break;
				case "ResidentinnenSection":
					this.orderDatenschutz = 5
					this.orderInfo = 4
					this.orderResidentinnen = 1
					this.orderResidenz = 2
					this.orderAusstellung = 3
					break;
				case "ResidenzSection":
					this.orderDatenschutz = 5 
					this.oderInfo = 3
					this.orderResidentinnen = 4
					this.orderResidenz = 1
					this.orderAusstellung = 2
					break;
				case "AusstellungSection":
					this.orderDatenschutz = 5 
					this.orderInfo = 2
					this.orderResidentinnen = 3
					this.orderResidenz = 4
					this.orderAusstellung = 1
					break;
			}
		},
	}
}
</script>

<style scoped >
#NavigationMenu {
	position: fixed;
	left: 0.3rem;
}
img {
	z-index: 10000;
	cursor: pointer;
	position:relative;
}
.buttons {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow: hidden;
        transition: 1.4;
}
.fullHeight {
	height: fit-content;
	transition: 1.5s
}
</style>
