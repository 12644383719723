<template>
	<div
		id="NavigationModule"
	>
		<NavigationMenu/>
	</div>
</template>

<script>
import NavigationMenu from './NavigationMenu.vue'
export default {
	name: "NavigationModule",
	components: {
		NavigationMenu,
	},
	props: [
	],
	data () {
		return {
		}
	},
	mounted () {
	},
	created () {
	},
	computed: {
	},
	methods: {
	},
	watch: {
	}
}
</script>

<style scoped >
#NavigationModule {
	box-sizing: border-box;
	z-index: 100;
	position: absolute;
	margin-top:0.3rem;
	width: 100%;
}
</style>
