var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"post",style:({
	marginTop: _vm.marginTop * 0.4 + 'vh',
	marginRight: _vm.marginRight + 'rem',
	marginLeft: _vm.marginLeft + 'rem',
	width: _vm.width + 'rem'
	}),attrs:{"id":"video"}},[_c('div',{staticClass:"orange-bk mark"}),(_vm.isExpanded)?_c('PostVideoModal',{attrs:{"link":_vm.post.contentHTML}}):_vm._e(),_c('div',{staticClass:"trns cutOut",style:({
		width: _vm.isExpanded ? _vm.width + 'rem' : _vm.widthClosed + 'rem',
		height: _vm.isExpanded ? '100vh' : _vm.heightClosed + 'vh',
		}),on:{"click":_vm.expandShape}},[_c('img',{staticClass:"trns",style:({
			width: _vm.width + 'rem',
			}),attrs:{"src":_vm.getShapeById(_vm.post.author)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }