import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
        state: {
               // baseURL: "http://localhost:8081/",
                baseURL: 'php/',

                scrollLeftPx: 0,
                scrollLeftSuspendended: false,
                screenWidthPx: 0,
                screenHeightPx: 0,
                startDatenschutzSectionPx: 0,
                startInfoSectionPx: 0,
                startResidentinnenSectionPx: 0,
                startResidenzSectionPx: 0,
                startAusstellungSectionPx: 0,

                questions: [],
                selectedQuestions: [],
                selectedResidents: [9, 10, 11],
                filteredPosts: [],
                assets: [],
                sortPostsBy: 'date',
                firstTime: true
        },
        getters: {
                isScrollSuspended: state => {
                        return state.scrollLeftSuspendended
                },
                currentSection: state => {
                        let currSec = 'DatenschutzSection'
                        if (state.scrollLeftPx < state.startInfoSectionPx) {
                                return currSec
                        }
                        currSec = 'InfoSection'
                        if (state.scrollLeftPx < state.startResidentinnenSectionPx) {
                                return currSec
                        }
                        currSec = 'ResidentinnenSection'
                        if (state.scrollLeftPx < state.startResidenzSectionPx) {
                                return currSec
                        }
                        currSec = 'ResidenzSection'
                        if (state.scrollLeftPx < state.startAusstellungSectionPx) {
                                return currSec
                        } else {
                                return 'AusstellungSection'
                        }
                },
                getScrollLeftPx: state => {
                        return state.scrollLeftPx
                },
                getScreenWidthPx: state => {
                        return state.screenWidthPx
                },
                getScreenHeight: state => {
                        return state.screenHeightPx
                },
                getStartAusstellung: state => {
                        return state.startAusstellungSectionPx
                },
                getStartResidenz: state => {
                        return state.startResidenzSectionPx
                },
                getStartResidentinnen: state => {
                        return state.startResidentinnenSectionPx
                },
                getStartInfo: state => {
                        return state.startInfoSectionPx
                },

                getQuestions: state => {
                        return state.questions
                },
                getPosts: state => {
                        return state.filteredPosts
                },
                isQuestionByIdSelected: state => question => {
                        return state.selectedQuestions.includes(question)
                },
                getBaseURL: state => {
                        return state.baseURL
                },
                getAssetsByPostId: state => postId => {
                        return Array.from(state.assets).filter(asset => asset.post === postId)
                },
                getSelectedResidents: state => {
                        return state.selectedResidents
                },
                isResidentSelected: state => id => {
                        const index = state.selectedResidents.find(resident => resident === id)
                        return index > -1
                },
                getAssets: state => {
                        return state.assets
                }
        },
        mutations: {
                setScreenWidthPx (state, payload) {
                        state.screenWidthPx = payload
                },
                setScreenHeightPx (state, payload) {
                        state.screenHeightPx = payload
                },
                setScrollLeftPx (state, payload) {
                        state.scrollLeftPx = payload
                },
                setStartDatenschutzSection (state, payload) {
                        state.startDatenschutzSectionPx = payload
                },
                setStartInfoSection (state, payload) {
                        state.startInfoSectionPx = payload
                },
                setStartResidentinnenSection (state, payload) {
                        state.startResidentinnenSectionPx = payload
                },
                setStartResidenzSection (state, payload) {
                        state.startResidenzSectionPx = payload - 100
                },
                setStartAusstellungSection (state, payload) {
                        state.startAusstellungSectionPx = payload
                },
                suspendScroll (state) {
                        state.scrollLeftSuspendended = true
                },
                activateScroll (state) {
                        state.scrollLeftSuspendended = false
                },

                sortPostOld (state) {
                        state.filteredPosts.sort(function (a, b) { return a.timestamp > b.timestamp })
                },
                sortPostNew (state) {
                        state.filteredPosts.sort(function (a, b) { return a.timestamp < b.timestamp })
                },
                sortPostRandom (state) {
                        state.filteredPosts.sort(function () { return Math.random() - 0.5 })
                },
                setAssets (state, payload) {
                        state.assets = payload
                },
                setQuestions (state, payload) {
                        state.questions = payload
                },
                setPosts (state, payload) {
                        state.filteredPosts = payload
                },
                toggleSelectionOfQuestion (state, question) {
                        const currentlySelectedQuestions = state.selectedQuestions.length
                        const filtered = Array.from(state.selectedQuestions).filter(q => q !== question)
                        if (currentlySelectedQuestions === filtered.length) {
                                filtered.push(question)
                        }
                        state.selectedQuestions = filtered
                },
                toggleSelectionOfResident (state, residentId) {
                        const index = state.selectedResidents.indexOf(residentId)
                        if (index > -1) {
                                state.selectedResidents.splice(index, 1)
                        } else {
                                state.selectedResidents.push(residentId
                                )
                        }
                },
                deactivateFirstTime (state) {
                        state.firstTime = false
                }

        },
        actions: {
                async updateStartPositionAusstellung (context) {
                        return new Promise(function(resolve) {
                                setTimeout( ()=>{
                                                        const newStartAusstellung = document.getElementById('AusstellungSection').offsetLeft
                                                        context.commit('setStartAusstellungSection', newStartAusstellung)
                                                        console.log('start ausstellung: ', newStartAusstellung)
                                                        resolve();
                                                }, 10) })
                },
                async fetchQuestions (context) {
                        const baseURL = context.state.baseURL
                        return	axios.get(baseURL + 'getQuestions.php').then(resp => {
                                const acceptedQuestions = Array.from(resp.data).filter(question => question.acceptedBy !== null)
                                context.commit('setQuestions', acceptedQuestions.sort(() => Math.random() - 0.5))
                        })
                },
                async updatePostList (context) {
                        // Logischen Ausdruck zum filtern nach Fragen erstellen: ...WHERE  question=1 OR question=3 OR ...
                        const question_ids_exp_array = []
                        Array.from(context.state.selectedQuestions).forEach(question => {
                                const exp = 'question=' + question.id
                                question_ids_exp_array.push(exp)
                        })
                        let question_ids_exp
                        if (question_ids_exp_array.length === 0) {
                                question_ids_exp = 'true'
                        } else {
                                question_ids_exp = question_ids_exp_array.join(' OR ')
                        }

                        // Logischen Ausdruck zum Filtern nach Residents erstellen: ...WHERE author=3 OR author=13 OR ...
                        const author_ids_exp_array = []
                        Array.from(context.state.selectedResidents).forEach(id => {
                                const exp = 'author=' + id
                                author_ids_exp_array.push(exp)
                        })
                        let author_ids_exp
                        if (author_ids_exp_array.length === 0) {
                                author_ids_exp = 'true'
                        } else {
                                author_ids_exp = author_ids_exp_array.join(' OR ')
                        }

                        const baseURL = context.state.baseURL
                        return axios.get(baseURL + 'getFilteredPosts.php', {
                                params: {
                                        question_ids_exp: question_ids_exp,
                                        author_ids_exp: author_ids_exp
                                }
                        }).then(resp => {
                                let posts = resp.data
                                if (context.state.firstTime) {
                                        posts = posts.sort( () => Math.random() - 0.5 );
                                        posts = posts.slice(0, 8)
                                }
                                console.log('authors: ' + author_ids_exp + ' questions: ' + question_ids_exp)
                                context.commit('setPosts', posts)
                                if (context.state.firstTime) {
                                        context.commit('deactivateFirstTime')
                                }
                        })
                },
                async fetchAssets (context) {
                        const baseURL = context.state.baseURL
                        return axios.get(baseURL + 'getAssets.php').then(resp => {
                                context.commit('setAssets', resp.data)
                        })
                }
        }
})

export default store
