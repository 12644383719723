var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"BlueTextBox"},[_c('ColorFade',{style:({
		zIndex: _vm.opened ? '100' : 'unset'
		}),attrs:{"color":"blue"},nativeOn:{"click":function($event){return _vm.$emit('clicked')}}}),_c('div',{staticClass:"TextBoxContent red-cl transition",style:({
		width: _vm.opened ? '100%' : '0%',
		background: _vm.opened ? '#b2b2b2' : 'rgb(0,0,0,0)' ,
		overflowY: _vm.opened ? 'auto' : 'hidden'}),on:{"click":function($event){return _vm.$emit('clicked')},"mouseenter":_vm.mouseenter,"mouseleave":_vm.mouseleave}},[_vm._t("default")],2),_c('ColorFade',{staticClass:"transition",style:({
		zIndex: _vm.opened ? '100' : 'unset',
		opacity: _vm.opened ? '100' : '0' }),attrs:{"color":"blue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }