<template>
	<div
		id="ResidentinnenSection"
		ref="Section"
		class="red-bk"
	>
		<ResidentinnenSectionVideoModal 
			@closeModal="modalVisible = false"
			v-if="modalVisible"
			v-bind:bioText="currentSelection.bioText"
			v-bind:content="currentSelection.content"
			v-bind:title="currentSelection.title"
		/>
		<div class="NavigationLine blue-bk"></div>
		<div
			@click="openModalSunny"
			id="IntroductionSunny"
			class="cutOut"
		/>
		<div
			@click="openModalSarafina"
			id="IntroductionSarafina"
			class="cutOut"
		/>
		<div
			@click="openModalMonika"
			id="IntroductionMonika"
			class="cutOut"
		/>
</div>
</template>

<script>
import {SectionMixin} from '../../mixins/SectionMixin.js'
import {ShapesMixin} from '../../mixins/ShapesMixin.js'
import ResidentinnenSectionVideoModal from './ResidentinnenSectionVideoModal.vue'
export default {
	name: "ResidentinnenSection",
	mixins: [SectionMixin, ShapesMixin],
	components: {
		ResidentinnenSectionVideoModal
	},
	props: [
	],
	data () {
		return {
			modalVisible: false,
			contentSunny: "<img src='" +
				require('../../../../assets/residentinnen/tempSunnyKlein.png') + 
				"' style=\"width: 100%; position: relative; top: -22rem\" />",
			contentSarafina:"<img src='" +
				require('../../../../assets/residentinnen/tempSarafinaKlein.png') + 
				"' style=\"width: 100%; position: relative; top: -10rem\" />",

			contentMonika: "<img src='" +
				require('../../../../assets/residentinnen/tempMonika2.jpg') + 
				"' style=\"width: 100%; position: relative; top: -3rem\" />",

			bioMonika: "<p style=' margin-top: 3.4rem'>ist eine Deutsch-Polnische interdisziplinäre Künstlerin. Mit ihrem Hintergrund in Textilkunst, Psychologie und Choreographie, verbindet sie in ihrem Werk Performance, (Textil- & Holz-) Skulptur, Video, Photographie und Malerei.\n" +
			"In ihrer multimedialen Arbeit erforscht sie die Strukturen des Selbst durch eine vielschichtige Analyse von sich konstant verändernden Zuständen: Anatomie, Psychologie und Umwelt. Ihre Forschungen zu (generationsübergreifenden) Traumata entwickeln sich aus ihren Recherchen über die kollektive und individuelle Geschichte ihrer Polnisch-Deutschen Vorfahren.</p>" +
          "In 2017 graduierte sie mit einem MA Art and Science vom Central Saint Martins in London, und wurde mit ihrer Abschlussarbeit für den Studio Makers Prize von Outset X Tiffany’s nominiert. Seither wurden ihre Arbeiten international präsentiert, u.a. am Arts Catalyst in London, Tate Exchange im Tate Modern, Foreign Affairs Festival in Berlin, Khalil Sakakini Kulturzentrum in Ramallah. Als Gastdozentin wurde sie für ihre tiefgehende Arbeit zu renommierten Institutionen wie dem Garage Museum in Moskau und Chelsea College of Arts in London eingeladen.\n" +
          "</br><a style='color: #3c2832' href='https://monikadorniak.com/' target='_blank'>www.monikadorniak.com</a></br>",
			bioSarafina: "<p style='text-indent: 18.5rem; margin-top: 0.8rem'>(*1994 Eugene, Oregon, US) ist eine Deutsch-US-amerikanische Künstlerin, die in Leipzig lebt und arbeitet. Ihre interdisziplinären Arbeiten bilden performative und bildhafte Momente, die punktuell aus einem Prozess der Bewegungsarbeit und -recherche hervortreten und sich auf Zwischenbereiche des menschlichen Bewusstseins und Daseins beziehen. Sie studierte Freie Kunst bei Aurelia Mihai, Donna Kukama und Thomas Rentmeister an der HBK Braunschweig und der Malmö Art Academy, Schweden.</p>	" +
          "Ihre Werke wurden unter anderem im Mönchehaus Museum Goslar, Kunstschaufenster Wolfsburg, Keller Drei, auf dem Rittergut Lucklum und in Zusammenarbeit mit dem DIS-PLAY Kunstkollektiv in Braunschweig (DE), Hannover (DE), Timișoara (RO) und Los Angeles (US) gezeigt.\n" +
          "McLeod wurde von der Studienstiftung des deutschen Volkes gefördert und gewann 2013 den National Scholastic Art Award und den Scholastic Art Award State of Vermont.\n" +
          "</br><a style='color: #3c2832' href='https://sarafinamcleod.com/' target='_blank'>www.sarafinamcleod.com</a></br>",
			bioSunny: "<p style='text-indent: 16rem; margin-top: 0.8rem'>(*1991, Wien, AT) ist Performance Künstler*in. Sunny studierte an der Akademie der bildende Künste Wien sowie in Mexiko-Stadt und entwirft Choreographien, Videos und textile Skulpturen. Mittels performativer Strategien untersucht Sunny verkörperte und repräsentative Elemente von Protest im öffentlichen Raum, sowie Sensibilität und Teen-Spirit von kollektiven Körpern.</p> Pfalzer’s Arbeiten und Recherche wurden u.a. in den KW Berlin, Shedhalle Zürich, Ashley Berlin und Les Urbaines Lausanne gezeigt. Pfalzer kollaborierte in 2020 mit Young Boy Dancing Group und Perfocraze International Artist Residency Ghana. Zuletzt erhielt Pfalzer das österreichische Startstipendium. Sunny Pfalzer lebt in Berlin, Wien und Ternitz. </br><a style='color: #3c2832' href='https://sunnyp.net/' target='_blank'>www.sunnyp.net</a></br></br>",
			currentSelection: {}
		}
	},
	mounted () {
	},
	created () {
	},
	computed: {
	},
	methods: {
		openModalSunny(){
			this.currentSelection = {bioText: this.bioSunny, content: this.contentSunny, title: "SUNNY PFALZER"}
			this.modalVisible =true
		},
		openModalSarafina() {
			this.currentSelection = {bioText: this.bioSarafina, content: this.contentSarafina, title: "SARAFINA MCLEOD"}
			this.modalVisible =true
		},
		openModalMonika() {
			this.currentSelection = {bioText: this.bioMonika, content: this.contentMonika, title: "MONIKA GABRIELA DORNIAK"}
			this.modalVisible =true
		}
	},
	watch: {
	}
}
</script>

<style scoped >
#ResidentinnenSection {
	width: 191rem;
	position: relative;
	height: 100%;
	padding-right: 17rem;
	display: flex;
	flex-direction: row;
}
.cutOut {
	cursor: pointer;
	z-index: 0;
	width: 70rem;
}
#IntroductionSunny {
	background: url('../../../../assets/residentinnen/Kontur2.png') no-repeat;
	top: 1rem;
	background-size: contain;
	position: relative;
}
#IntroductionSunny:hover {
	background: url('../../../../assets/residentinnen/Deckblatt2.png') no-repeat;
	top: 1rem;
	background-size: contain;
	position: relative;
}
#IntroductionSarafina {
	background: url('../../../../assets/residentinnen/Kontur1.png') no-repeat;
	background-size: contain;
	position: relative;
	bottom: -14rem;
	margin: 0 -10rem;
}
#IntroductionSarafina:hover {
	background: url('../../../../assets/residentinnen/Deckblatt1.png') no-repeat;
	background-size: contain;
	position: relative;
	bottom: -14rem;
	margin: 0 -10rem;
}
#IntroductionMonika {
	background: url('../../../../assets/residentinnen/Kontur3.png') no-repeat;
	background-size: contain;
	position: relative;
}
#IntroductionMonika:hover {
	background: url('../../../../assets/residentinnen/Deckblatt3.png') no-repeat;
	background-size: contain;
	position: relative;
}
</style>
