<template>
    <div
        id="HorizontalScroll"
        ref="HorizontalScrollContainer"
        @scroll="scroll"
        @wheel="wheel"
    >
        <div class="flexbox">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "HorizontalScroll",
    components: {
    },
    data: function () {
        return {
            scrollDirectionTwist: true,
        };
    },
    methods: {
        setScreenSize(){
            this.$store.commit('setScreenWidthPx', this.$refs.HorizontalScrollContainer.clientWidth)
            this.$store.commit('setScreenHeightPx', this.$refs.HorizontalScrollContainer.clientHeight)
        },
        deactivateTwist() {
            this.scrollDirectionTwist = false
        },
        scroll() {
            this.$store.commit('setScrollLeftPx',this.$refs.HorizontalScrollContainer.scrollLeft)
        },
        wheel(e) {
            if (e.deltaY === 0) {
                this.scrollDirectionTwist = false
            }
            if (this.scrollDirectionTwist) {
                if (  !this.$store.getters.isScrollSuspended ) {
                    let counter
                    if(this.$browserDetect.isFirefox) {
                        counter = e.deltaY * 1
                    } else {
                        counter = e.deltaY * 1
                    }
                    while (counter >= 0) {
                        this.$refs.HorizontalScrollContainer.scrollLeft += 1
                        counter--
                    }
                    while (counter <= 0) {
                        this.$refs.HorizontalScrollContainer.scrollLeft -= 1
                        counter++
                    }
                }
            }
        },
    },
    mounted() {
        this.setScreenSize()	
        window.addEventListener('resize', this.setScreenSize)
        window.addEventListener('touchstart', this.deactivateTwist)
    },
    destroyed() {
        window.removeEventListener('resize', this.setScreenSize)
        window.removeEventListener('touchstart', this.deactivateTwist)
    }
};
</script>

<style scoped >
#HorizontalScroll {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}	
*::-webkit-scrollbar {
    width: 0.15rem;
    height: 0.15rem;
}
*::-webkit-scrollbar-track {
    background:  #3c2832;
}
*::-webkit-scrollbar-thumb {
    background: blue;
}
.flexbox {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}	
</style>
