<template>
    <div id="Questions">
        <div class="NavigationLine orange-bk"></div>
        <QuestionsList></QuestionsList>
        <QuestionsAddNew></QuestionsAddNew>
    </div>
</template>

<script>
import QuestionsList from "./QuestionsList.vue";
import QuestionsAddNew from "./QuestionsAddNew.vue";
export default {
    name: "Questions",
    components: {QuestionsAddNew, QuestionsList}
}
</script>

<style scoped>
#Questions {
    position: relative;
    height: 100%;
    width: 30rem;
    display: flex;
    flex-direction: column;
}
</style>
