<template>
    <div
        class="modalVideo trns"
        ref="modalVideo"
        v-bind:style="{
        width: videoWidth + 'rem',
        top: videoTop+ 'px',
        height: videoHeight + 'rem'
        }"
        @click="show"
    >
        <template v-if="isVisible">
            <ColorFade color="orange" v-bind:style="{ opacity: opacity }" />
            <iframe
                v-bind:src="link + '?&autoplay=1'"
                v-bind:style="{ opacity: opacity }"
                allowfullscreen
            ></iframe>
            <ColorFade color="orange" v-bind:style="{ opacity: opacity }" />
        </template>
    </div>
</template>

<script>
import ColorFade from '../../../elements/ColorFade.vue'
            export default {
                name: "PostVideoModal",
                data: function () {
                    return {
                        testLink: "https://www.youtube.com/embed/TXsNk03ygD4",
                        videoWidth: 66,
                        isVisible: false,
                        opacity: 0,
                        videoTop: 0
                    };
                },
                components: {
                    ColorFade,
                },
                props: {
                    link: String,
                },
                methods: {
                    randomNumberBetween(a, b) {
                        return Math.random() * (b - a) + a;
                    },
                    show() {
                        this.isVisible = true;
                        console.log("show");
                        this.opacity = 100;
                    },
                    setVideoTop() {
                        this.videoTop = (this.$store.getters.getScreenHeight - this.$refs.modalVideo.getBoundingClientRect().height)/4 
                    },
                },
                computed: {
                    videoHeight() {
                        return this.videoWidth / (16 / 9 ) 
                    },
                },
                mounted() {
                    this.videoWidth = this.randomNumberBetween(77, 82);
                    this.setVideoTop()
                },
            };
</script>

<style scoped>
.modalVideo {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    z-index: 33334444443;
    overflow-x: unset;
    cursor: pointer;
}
iframe {
    border: 0;
    width: 100%;
    height: 100%;
}
</style>
