<template>
<BlueTextBox 
@clicked="boxOpen = !boxOpen"
id="Kalender" 
v-bind:opened="boxOpen"
>
<ColorFade
color="blue"
id="MarkierungKalender"
/>
<StrokeTitle 
v-bind:title="'TERMINE'" 
v-bind:colorFront="'#22515B'"
v-bind:colorBack="'blue'"
/>
<div class="placeholder" />
<div v-for="(entry, i) in entries" v-bind:key="i" class="Event">
<p class="day" v-bind:class="{even: i % 2 !== 0, odd: i%2===0}">{{entry.day}}</p>
<p class="time" v-bind:class="{even: i % 2 !== 0, odd: i%2===0}">{{entry.time}}</p>
<div v-bind:style="{position: 'relative', height: '3rem', left: i%2==1 ? 88 / entry.title.length**0.65 + 'rem' :'unset'}">
<StrokeTitle
v-if="boxOpen"
class="EventTitle"	
v-bind:title="entry.title"
v-bind:colorFront="'#22515B'"
v-bind:colorBack="'blue'"
/>
</div>
<p class="persons" v-bind:class="{even: i % 2 !== 0, odd: i%2===0}">{{entry.persons}}</p>
<div class="EventDescription" v-html="entry.description" v-bind:class="{even: i % 2 !== 0, odd: i%2===0}">
</div>
<br>
</div>
</BlueTextBox>
</template>

<script>
import {BoxOpenMixin} from '../../mixins/BoxOpenMixin.js'
import ColorFade from '../../elements/ColorFade.vue'
import StrokeTitle from '../../elements/StrokeTitle.vue'
import BlueTextBox from '../../elements/BlueTextBox.vue'
export default {
name: "InfoSectionCalendar",
              mixins: [BoxOpenMixin],
              components: {
                      StrokeTitle,
                      BlueTextBox,
                      ColorFade
              },
props: [
],
data () {
        return {
boxOpen: false,
         openByScroll: true,
         openAt: 220,
         entries: [

{
title: "SPAZIERGANG",
persons: "mit Monika Gabriela Dorniak",
day: " Sonntag, 27. Juni 2021",
time: "14:00 Uhr",
description: "<p>Künstler:innen-Spaziergang / Vortrag unter dem Titel &quot;Collective Synchronization&quot; mit der Residierenden Monika Gabriela Dorniak</p><p>Für die Teilnahme bitte eine kurze Nachricht an: </br><a href='mailto:mail@hold-hands.net'>mail@hold-hands.net</a></p>"
},
{
title:"ERÖFFNUNG",
persons:"",
day:"Freitag, 25. Juni 2021",
time:"18:00 Uhr",
description: "<p>Eröffnung des analogen Teils der Ausstellung im Kunstverein Leipzig (Kolonnadenstraße 6, 04109 Leipzig) - im Rahmen von KV Support; mit einem performativen Willkommen von Sunny Pfalzer</p>"
},
        {
title: "ERÖFFNUNG",
persons: "",
day: "Mittwoch, 23. Juni 2021",
time: "17:00 — 18:30",
description: "<p>Eröffung des digitalen Teils der Hold Hands Gruppenausstellung mit einer Einleitung unter dem Titel &quot;Connecting to thoughts on the perception of the body&quot; von Sarafina McLeod sowie einem Input und anschließendem Gespräch mit der Kultur- und Medienwissenschaftlerin Dr. Sara Morais dos Santos Bruss</p><p>Für die Teilnahme bitte eine kurze Nachricht an: </br><a href='mailto:mail@hold-hands.net'>mail@hold-hands.net</a></p>"
        },


         {
title: "STUDIOBESUCH",
       persons: "mit Agnieszka Roguski",
       day: "Donnerstag, 22. April 2021",
       time: "19:00 — 20:30 Uhr",
       description: "<p>In Woche 4 treffen wir uns, um die Entwicklungen der Projekte unserer Resident:innen weiter mitzuverfolgen. Für diesen Studiobesuch haben wir einen Gast eingeladen. Die Kuratorin Agnieszka Roguski wird mit den Residierenden über die entstandenen Projekte und den aktuellen Stand der Arbeitsprozesse reflektieren.</p><p>Für die Teilnahme bitte eine kurze Nachricht an: </br><a href='mailto:mail@hold-hands.net'>mail@hold-hands.net</a></p>"
         },
         {
title: "STUDIOBESUCH",
       persons: "",
       day: "Donnerstag, 8. April 2021",
       time: "10:00 — 11:45 Uhr",
       description: "<p>Diese Woche starten wir mit dem ersten öffentlichen Studiobesuch — ihr seid herzlich eingeladen teilzunehmen.</p><p>Während der Studiobesuche werden die Residierenden Einblicke in ihre künstlerische Praxis geben sowie ihr Projekt während der Residenz vorstellen. Anschließend gibt es die Möglichkeit für Fragen und Feedback, um mit den Resident:innen in den Austausch zu treten.</p><p>Für die Teilnahme bitte eine kurze Nachricht an: </br><a href='mailto:mail@hold-hands.net'>mail@hold-hands.net</a></p>"
         },
         {
title: "LISTENING SESSION",
       persons: "mit Sarafina McLeod",
       day: "Donnerstag, 15. April 2021",
       time: "17:30 — 19:00 Uhr",
       description: "<p>In Woche 3 treffen wir uns mit unseren residierenden Künstler:innen, um die Entwicklungen ihrer Projekte weiter mitzuverfolgen. Dieser Studiobesuch wird in Form von Audio-Präsentationen abgehalten. Sarafina McLeod wird durch ein von ihr angeleitetes Warm-Up auf diese Sitzung einstimmen.</p><p>Für die Teilnahme bitte eine kurze Nachricht an: </br><a href='mailto:mail@hold-hands.net'>mail@hold-hands.net</a></p>"
         },
         ]
        }
},
        mounted () {
        },
        created () {
        },
computed: {
          },
methods: {
         },
watch: {
       }
}
</script>

<style scoped >
#Kalender {
        position: absolute;
left: 90rem;
bottom: 0;
height: 33rem;
        z-index: 333;
width: 36rem;
}
#MarkierungKalender {
height: 1.5rem;
position: absolute;
top: -22.9rem;
left: 0;
}
.even {
        text-align: right;
        padding-left: 3rem;
}
.odd {
        padding-right: 3rem;
}
.Event {
position: relative;
          margin-left: 0.8rem;
}
.placeholder {
width: 100%;
height: 5rem;
}
.EventTitle {
        margin-left: -1rem;
}
.day{
margin:0.2rem 0;
}
.time {
margin:0.2rem 0;
       line-height:150%;
}
.persons {
margin:0.2rem 0;
}
</style>
