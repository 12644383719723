<template>
	<div
		id="ResidenzSection"
		ref="Section"
	>
		<ColorFade color="orange" v-bind:mirrored="true" />
		<Questions></Questions>
		<ColorFade color="orange" />
		<Posts></Posts>
	</div>
</template>

<script>
import Questions from "./questions/Questions.vue";
import {SectionMixin} from '../../mixins/SectionMixin.js'
import ColorFade from '../../elements/ColorFade.vue'
import Posts from "./posts/Posts.vue";
export default {
	name: "ResidenzSection",
	mixins: [SectionMixin],
	components: {Posts, ColorFade, Questions},
	methods: {}
}
</script>

<style scoped>
#ResidenzSection {
	position: relative;
	box-sizing: border-box;
	height: 100%;
	background: #b2b2b2;
	display: flex;
	flex-direction: row;
}
</style>

