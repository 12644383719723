<template>
  <div id="Posts" >
		<div class="NavigationLine orange-bk"></div>
    <div class="placeholder2"></div>
      <PostContainer
          v-for="post in $store.getters.getPosts"
          v-bind:key="post.idposts"
          v-bind:post="post"
      ></PostContainer>
    <div class="placeholder"></div>
  </div>
</template>
<script>
import PostContainer from "./PostContainer.vue";
export default {
  name: "Posts",
  components: { PostContainer },
    data: function() {
        return {
            firstTime: true
        }
    },
  computed: {
      posts() {
          return this.$store.getters.getPosts
      },
 }
}
</script>

<style>
.trns {
  transition: 1.7s;
}
#Posts {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 75rem;
  background: #b2b2b2;
}
.placeholder {
  height: 100%;
  width: 34rem;
}
.placeholder2 {
  height: 100%;
  width: 18.3rem;
}
</style>
