<template>
	<div id="Contact" >
		<a href="mailto:mail@hold-hands.net">
		<img
			class="mail"
			v-bind:style="{ opacity: orange ? '100' : '0' }"
			v-bind:src="pathToMailOrange"
		/>
		<img 
			class="instagram"
			v-bind:style="{ opacity: orange ? '0' : '100' }"
			v-bind:src="pathToMailBlue"
		/>
	</a>
	<a href="https://www.instagram.com/holdhandsresidenz/" target="_blank">
		<img
			class="mail"
			v-bind:style="{ opacity: orange ? '100' : '0' }"
			v-bind:src="pathToInstaOrange"
		/>
		<img
			class="instagram"
			v-bind:style="{ opacity: orange ? '0' : '100' }"
			v-bind:src="pathToInstaBlue"
		/>
	</a>
	</div>
</template>

<script>
export default {
	name: "ContactModule",
	components: {
	},
	props: [
	],
	data () {
		return {
			orange: false, 

			pathToMailOrange: require('../../../assets/icons/Mail-orange.png'),
			pathToInstaOrange: require('../../../assets/icons/Instagram-orange.png'),

			pathToMailBlue: require('../../../assets/icons/Mail-blau.png'),
			pathToInstaBlue: require('../../../assets/icons/Instagram-blau.png'),
		}
	},
	mounted () {
	},
	created () {
	},
	computed: {
		scrollPositionRight() {
			return this.$store.getters.getScrollLeftPx + this.$store.getters.getScreenWidthPx
		},
	},
	methods: {
	},
	watch: {
		scrollPositionRight: function(newVal) {
			if( newVal < this.$store.getters.getStartAusstellung && newVal > 
				this.$store.getters.getStartResidenz + 100) {
				this.orange = true
			} else {
				this.orange = false
			}
		}
	}
}
</script>

<style scoped >
#Contact {
	position: fixed;
	bottom: 0;
	right: 0;
}
img {
	margin-right: 0.55555rem;
	margin-bottom: 0.5rem;
	height: 2rem;
	transition: 1s;
}
.mail {
	position: absolute;	
	z-index: 555555555;
}
.instagram {
	z-index: 555555555;
}
</style>
