export const SectionMixin = {
  name: 'SectionMixin',
  data: function () {
    return {
    }
  },
  methods: {
    setSectionStart () {
      const ol = this.$refs.Section.offsetLeft
      this.$store.commit('setStart' + this.$options.name, ol)
    }
  },
  mounted () {
    window.addEventListener('resize', this.setSectionStart)
    this.$refs.Section.addEventListener('onresize', this.setSectionStart)
    this.setSectionStart()
  },
  destroyed () {
    window.removeEventListener('resize', this.setSectionStart)
  }
}
