<template>
        <HorizontalScroll>
        <NavigationModule/>
        <DatenschutzSection/>
        <InfoSection/>
        <ResidentinnenSection/>
        <ResidenzSection/>
        <AusstellungSection/>
        <ContactModule/>
        </HorizontalScroll>
</template>

<script>
import ContactModule from './components/ContactModule.vue'
import NavigationModule from './components/navigation/NavigationModule.vue'
import DatenschutzSection from './sections/datenschutz/DatenschutzSection.vue'
import InfoSection from './sections/info/InfoSection.vue'
import ResidentinnenSection from './sections/residentinnen/ResidentinnenSection.vue'
import ResidenzSection from './sections/residenz/ResidenzSection.vue'
import AusstellungSection from './sections/ausstellung/AusstellungSection.vue'
import HorizontalScroll from './components/HorizontalScroll.vue'
export default {
        name: "App",
        components: {
                HorizontalScroll,
                DatenschutzSection,
                InfoSection,
                ResidentinnenSection,
                ResidenzSection,
                AusstellungSection,
                NavigationModule,
                ContactModule
        },
        data: function () {
                return {
                };
        },
        methods: {
                goToInfo(){
                        let pos = this.$store.getters.getStartInfo
                        document.getElementById('HorizontalScroll')
                                .scrollTo({left: pos})
                },
                goToAusstellung(){
                        let pos = this.$store.getters.getStartAusstellung+44;
                        document.getElementById('AusstellungSection').scrollIntoView();
                       // document.getElementById('HorizontalScroll')
                       //         .scrollTo({left: pos+1111111, behavior: 'smooth'})
                },
        },
        mounted() {
                this.$store.dispatch('fetchQuestions')
                        .then( () => {
                                this.$store.dispatch('fetchAssets')
                                        .then ( () => {
                                                this.$store.dispatch('updatePostList')
                                                        .then ( () => {
                                                                this.$store.dispatch('updateStartPositionAusstellung').then ( ()=> {
                                                                        this.$store.commit("sortPostRandom");
                                                                        console.log('after promise returned')
                                                                        setTimeout( () => {this.$store.dispatch('updateStartPositionAusstellung') }, 2000);
                                                                        this.goToAusstellung()
                                                                })	
                                                        })
                                        } )
                        })
        },
};
</script>

<style>
@import './global.css';
body {
        margin:0;
}
</style>
