<template>
        <div
                        id="longText"
                        class="trns"
                        ref="post"
                        v-bind:style="{
                                       marginTop: (marginTop + 2) + 'vh',
                                       height: height + 'vh',
                                       width: widthOpen + 'rem',
                                       marginRight: marginRight + 'rem'
                                       }"
                        @click="toggleExpand"
                        >
                        <ColorFade color="orange" class="mark" />
                        <ColorFade color="orange" />
                        <div
                                        class="textBox trns"
                                        v-bind:style="{
                                                       opacity: isExpanded ? '100' : '0',
                                                       width: isExpanded ? '100%' : '0%',
                                                       }"
                                        @mouseenter="deactivateScroll"
                                        @mouseleave="activateScroll"
                                        >
                                        {{ post.contentHTML }}
                        </div>
                                <ColorFade color="orange" 
                                           v-bind:style="{
                                                          transition: '2.4s',
                                                          opacity: isExpanded ? 100 : 0,
                                                          }"
                                           ></ColorFade>
        </div>
</template>

<script>
import ColorFade from '../../../elements/ColorFade.vue'
import { postMixin } from "./PostMixin.js";
export default {
        mixins: [postMixin],
        name: "PostTextLong",
        components: { ColorFade },
        data: function () {
                return {
                        height: 0,
                        widthOpen: 0,
                        marginRight: 0,
                        marginTop: 0
                };
        },
        computed: {
                textlength() {
                        return this.post.contentHTML.length;
                }
        },
        mounted() {
                this.height = this.textlength <190 ? this.randomNumberBetween(15, 22) : this.randomNumberBetween(30, 50);
                this.widthOpen = this.textlength < 190? this.randomNumberBetween(15, 16) :this.randomNumberBetween(20, 44);
                this.marginRight = this.randomNumberBetween(-3,10);
                this.marginTop = this.randomNumberBetween(3,50)
        },
};
</script>

<style scoped>
#longText {
        display: flex;
        flex-direction: row;
}
.textBox {
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-left: 1.2rem;
        padding-right: 0.3rem;
        background: #b2b2b2;
}
.mark {
        position: absolute;
        top: 0;
        height: 0.9rem;
}
*::-webkit-scrollbar {
        width: 1rem;
}
*::-webkit-scrollbar-track {
        z-index: 300;
        background: rgba(21,255,0,0);
        width: 3rem;
}
*::-webkit-scrollbar-thumb {
        z-index: 300;
        background: #3c2832;
        width: 3rem;
}
*::-webkit-scrollbar-thumb:hover {
        z-index: 300;
        background: #3c2832;
        width: 3rem;
}
</style>
