<template>
        <div
                        id="AusstellungSection"
                        ref="Section"
                        class="red-bk"
                        >
                        <div class="NavigationLine blue-bk"></div>
                        <ColorFade color="blue" v-bind:mirrored="true" v-bind:style="{height: '100%'}" />
                        <div id="Download">
                                <a href="./Hold Hands Residenz.zip" download>
                                        <StrokeTitle 
                                   class="text_dl1"
                                   v-bind:title="'DOWNLOAD'" 
                                   v-bind:colorFront="'#22515B'"
                                   v-bind:colorBack="'blue'"
                                   />
                                        <StrokeTitle 
                                   class="text_dl2"
                                   v-bind:title="'AUSSTELLUNG DIGITAL :'" 
                                   v-bind:colorFront="'#22515B'"
                                   v-bind:colorBack="'blue'"
                                   />
                                        <img 
                                   id="IconDownload"
                                   src="../../../../assets/icons/download.png" />
                                </a>
                        </div>
                        <img 
                                   id="HoldHandsLogoAusstellung"
                                   src="../../../../assets/logo/Logo_Ausstellung.png" />
                        <BlueTextBox
                                        id="AusstellungsText"
                                        v-bind:opened="boxOpen"
                                        @clicked="boxOpen = !boxOpen"
                                        >
                                        <StrokeTitle
                                                        v-bind:title="'KV LEIPZIG'"
                                                        v-bind:colorFront="'#22515B'"
                                                        v-bind:colorBack="'blue'"
                                                        />
                                        <StrokeTitle
                                                        id="Ausstellungsdatum"
                                                        v-bind:title="'25. JUNI 2021 AB 18 UHR'"
                                                        v-bind:colorFront="'#22515B'"
                                                        v-bind:colorBack="'blue'"
                                                        />

                                        <br>
                                        <br>
                                        <br>
                                        [english version below]
                                        <br>
                                        <p id="ersterAbsatz" >
                                        „Wir morphten durch den Monat, wurden Tiere und erinnerten uns. An Gerüche,
                                        Bewegungen und daran, wie der Stoff deines T-Shirts sich an meiner Wange reibt.“
                                        </p>
                                        <p class="links">
                                        Die digitale Gruppenausstellung zeigt Projekte, die während des Hold Hands Online-Residenzprogramms 2021 entwickelt wurden. Die teilnehmenden Künstler:innen – Monika Gabriela Dorniak, Sarafina McLeod und Sunny Pfalzer – nutzten im April 2021 die Webseite <a href="www.hold-hands.net">www.hold-hands.net</a> als Arbeitsraum und standen durch ihre Arbeitsprozesse im Austausch mit einer interessierten Öffentlichkeit. 
                                        </p >
                                        <p>
                                        Sarafina McLeod nutzte die Residenzzeit, um ihre Bewegungsrecherche und Körperarbeit zu vertiefen. Eine Auswahl an Notationen gibt Einblick in ihre Untersuchungen dazu, wie Zustände des körperlichen Befindens im Digitalen erfahrbar gemacht werden können. Die Künstlerin beschreibt den Bereich des Solar Plexus als einen Ort von Energie, Offenheit und Wärme. McLeod’s Video <i>The sun touches us all</i> (2021) zeigt die Aktivierung dieser Körperstelle und offenbart die Vorstellung von hieraus fließender Energie, die auf die Umgebung des horizontalen Körpers abstrahlt. Auch Sunny Pfalzer’s Podcast <i>Feel a Ghost</i> (2021) befasst sich mit der Wahrnehmung des eigenen Körpers und der Relation zum umliegenden Raum. Die Zuhörer:innen werden durch performative Strategien eingeladen Bewegungen und Klänge zu erkunden, die sich sowohl im Innen als auch im Außen abspielen. Durch Pfalzer’s Audiostück entsteht eine Beziehung zwischen den Kostümen der Performance <i>Hold a Ghost</i> (2020) – die sich nun zu einer textilen Installation zusammensetzen – und den Zuhörer:innen, die diese Transformation mitverfolgen. <i>Your body is a water vessel</i> (2021) ist ein Score der Künstlerin Monika Gabriela Dorniak, den sie uns für den digitalen Teil der Gruppenausstellung als Audioaufnahme sowie Verschriftlichung bereitstellt. Dieser Score reflektiert die fortlaufende Auseinandersetzung Dorniak’s mit dem Themenfeld des intergenerationalen Traumas. In <i>Your body is a water vessel</i> (2021) wird das Publikum eingeladen den eigenen Körper als Gefäß wahrzunehmen und den äußerlichen (traumtischen) Erschütterungen anhand wellenförmiger Bewegungen nachzufühlen.
                                        </p>
                                        <p class="links">
                                        Die Werke stehen den Besucher:innen zum Download auf das private Endgerät bereit. Die Urheber:innenrechte obliegen den jeweiligen Künstler:innen. Die Vervielfältigung oder Weiteleitung der Daten ist nicht vorgesehen. Bitte wenden Sie sich andernfalls direkt an die Künstler:in – Vielen Dank!
                                        </p>
                                        <br>
                                        <br>
                                        <p id=ersterAbsatz>
                                        "We morphed through the month, we became animals and we memorized. Scents,
movements, and the feeling of your t-shirt's fabric rubbing against my cheek."
                                        </p>

                                        <p class="links">
The digital group exhibition features projects developed during the Hold Hands online residency program in 2021. The participating artists—Monika Gabriela Dorniak, Sarafina McLeod, and Sunny Pfalzer—used the website <a href="www.hold-hands.net">www.hold-hands.net</a> as a workspace during April 2021 and interacted with an interested public through their work processes. 
                                        </p>

                                        <p>
                                        Sarafina McLeod chose to use the residency to deepen her movement research and bodywork. A selection of notations provides insight into her investigations regarding how states of physical being can be experienced through the digital. The artist describes the solar plexus area as a place of energy, openness and warmth. McLeod's video <i>The sun touches us all</i> (2021) shows the activation of this part of the body and reveals the notion of energy flowing from here, radiating out onto the surroundings of the horizontal body. Sunny Pfalzer's podcast <i>Feel a Ghost</i> (2021) also explores the perception of one's body and its relation to the surrounding space. Through performative strategies, listeners are invited to explore movements and sounds that take place both inside and outside. Through Pfalzer's audio piece, a relationship is created between the costumes of the performance <i>Hold a Ghost</i> (2020) — which now come together to form a textile installation — and the audience members who witness this transformation. <i>Your body is a water vessel</i> (2021) is a score by the artist Monika Gabriela Dorniak, which she offers us for the digital part of the group exhibition as an audio recording as well as a transcription. This score reflects Dorniak's ongoing engagement with the thematic field of intergenerational trauma. In <i>Your body is a water vessel</i> (2021) the audience is invited to perceive their bodies as vessels and to feel the external (traumatic) vibrations through wave-like movements.
                                        </p>
                                        <p class="links">
The works are available for visitors to download onto their private devices. The copyrights belong to the respective artists. The reproduction or forwarding of the data is not intended. Otherwise, please contact the artist directly - Thank you!
                                        </p>
                        </BlueTextBox>
        </div>
</template>

<script>
import BlueTextBox from '../../elements/BlueTextBox.vue'
import ColorFade from '../../elements/ColorFade.vue'
import StrokeTitle from '../../elements/StrokeTitle.vue'
import {SectionMixin} from '../../mixins/SectionMixin.js'
export default {
        name: "AusstellungSection",
        mixins: [SectionMixin],
        components: {
                ColorFade,
                StrokeTitle,
                BlueTextBox
        },
        props: [
        ],
        data () {
                return {
                        boxOpen: true
                }
        },
        mounted () {
        },
        created () {
        },
        computed: {
        },
        methods: {
        },
        watch: {
        }
}
</script>

<style scoped >
#AusstellungSection {
        position: relative;
        min-width: 115rem;
        height: 100%;
}
#Download{
        position: absolute;
        left: 8em;
        top: 45rem;
}
#IconDownload{
        position: absolute;
        left: 24rem;
        height: 7.5rem;
        top: -0.4rem;
}
.text_dl1 {
        position: relative;
        top: 0rem;
}
.text_dl2 {
        position: absolute;
        top: 3.3rem;
}
.logo {
        background-image: url('../../../../assets/logo/Logo_Ausstellung.png');
        position: absolute;
        top: 11rem;
        left: 17rem;
        height: 50rem;
}
#HoldHandsLogoAusstellung {
        z-index: 0;
        position: absolute;
        height: 21rem;
        top: 11rem;
        left: 14rem;
}
#AusstellungsText {
        position: absolute;
        top: 5rem;
        height: 38rem;
        left: 71rem;
        width: 32rem;
}
#Ausstellungsdatum {
        position: absolute;
        top: 3.43rem;
}
#ersterAbsatz {
        font-style: italic;
}
.links {
        text-align: right;
}
</style>
